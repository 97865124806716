import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import FormSection from './views/FormSection';
import HeroSection from './views/Hero1.jsx';
import Navbar from './views/Navbar';
import Hero2 from './views/Hero2';
import Hero3 from './views/Hero3';
import Slider from './views/Slider.jsx';
import Gracias from './views/Gracias'; // Import the Gracias component
import MapHero from './views/MapHero.jsx'
import RequirementsHero from './views/RequirementsHero.jsx'
import Requirements from './views/Requirements.jsx';
import Costos from './views/EstimarCostos.jsx'
import NoPermitido from './views/NoPermitido.jsx';
import Footer from './views/Footer.jsx';
import MobileForm from './views/Form/MobileForm.jsx';
import AboutUs from './views/Mobile/AboutUs.jsx';
import ContactUs from './views/Mobile/ContactUs.jsx';
import Documentos from './views/Mobile/Documents.jsx';
import FAQ from './views/FAQ.jsx';
function App() {
  // State to determine if the view is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  // Refs for both forms
  const formSectionRef = useRef(null);
  const mobileFormRef = useRef(null);

  // Event handler for resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to scroll to the correct form section based on the view
  const scrollToFormSection = () => {
    if(isMobile && mobileFormRef.current) {
      mobileFormRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if(formSectionRef.current) {
      formSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_6_qv6evc.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  return (
    <Router>
      <div className="App">
        <Navbar/>
        <Routes>
          <Route path="/" element={
            <>
              {/* Conditionally apply the ref based on the view */}
              <div ref={!isMobile ? formSectionRef : null}>
                <FormSection  scrollToForm={scrollToFormSection} />
              </div>
              <HeroSection/>
              <Hero2 scrollToForm={scrollToFormSection}/>
              <Slider scrollToForm={scrollToFormSection}/>
              <MapHero/>
              <RequirementsHero  scrollToForm={scrollToFormSection}/>
              <Requirements></Requirements>
              <Hero3></Hero3>
              <Costos></Costos>
              <NoPermitido></NoPermitido>
              <div className='d-block d-md-none'>
                <div className='p-5 mb-0'>
                  <p className='text-primary mb-0'>
                      Reserva tu cupo
                  </p>
                  <h1 className='beba-font'>
                      Recibe hasta 5 cotizaciones
                  </h1>
                </div>
                {/* Conditionally apply the ref based on the view */}
                <div ref={isMobile ? mobileFormRef : null}>
                  <MobileForm/> 
                </div>
              </div>
            </>
          } />
          <Route path="/gracias" element={<Gracias />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/documentos" element={<Documentos />} />
          <Route path="/FAQ" element={<FAQ />} />
        </Routes>
        <Footer scrollToForm={scrollToFormSection} />
      </div>
    </Router>
  );
}

export default App;
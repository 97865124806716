import "./Banner.css";
import garlas_llc_logo from '../../images/banner/garlasllc-banner-tittle.svg';
import BannerLogos from './BannerLogos';
import BannerLogosMobile from './BannerLogosMobile';

export default function Banner() {
    return (
        <section className="container px-3 mx-auto py-5 w-100" id="banner">
            <div className="d-flex flex-column gap-lg-5 gap-4 w-100">
                <div className='d-flex justify-content-center justify-content-lg-start'>
                    <img src={garlas_llc_logo} alt="Garlas LLC" height="36" width="auto" className='align-self-start' />
                </div>

                <div className='d-flex gap-3 w-100 flex-column flex-lg-row'>
                    <div className='d-flex flex-column gap-3 box-border width-side-right' >
                        <h3 className='beba-font text-primary text-center text-lg-start' style={{ fontSize: '45px', lineHeight: '1.2' }}>
                            Tu aliado más grande en los <wbr /> Estados Unidos para transportar <wbr /> tu vehiculo
                        </h3>
                        <div className='d-flex flex-column justify-content-between gap-2 rounded-4 bg-secondary text-white py-3 px-4'>
                            <p className='lato-font' style={{ fontSize: '27px', lineHeight: '32px' }}>Somos una red de empresas <wbr /> especializadas en el transporte de tu <wbr /> auto, moto, camión, bote, yate etc.</p>
                            <p className='fs-2 fw-bold text-uppercase d-flex justify-content-lg-end justify-content-center' style={{ fontSize: '33px', lineHeight: '40px' }}>Tu dinos!</p>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex width-side-left">
                        <BannerLogos />
                    </div>
                    <div className="d-flex d-lg-none w-100 overflow-hidden">
                        <BannerLogosMobile />
                    </div>
                </div>
            </div>
        </section>
    );
}
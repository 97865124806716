import logo_nomanejes_img from '../../images/banner/logo_nomanejes.svg';
import logo_puertoricocartransfer_img from '../../images/banner/logo_puertoricocartransfer.svg';
import logo_carrosacentroamerica_img from '../../images/banner/logo_carrosacentroamerica.svg';
import logo_prautotransport_img from '../../images/banner/logo_prautotransport.svg';
import logo_carmoversus_img from '../../images/banner/logo_carmoversus.svg';
import logo_shipshippersusa_img from '../../images/banner/logo_shipshippersusa.svg';
import logo_ratesautotransport_img from '../../images/banner/logo_ratesautotransport.svg';
import logo_tucarroporusa_img from '../../images/banner/logo_tucarroporusa.svg';
import logo_tcpamerica_img from '../../images/banner/logo_tcpamerica.svg';

import logo_ally_centraldispatch_img from '../../images/banner/logo_ally_centraldispatch.svg';
import logo_ally_seaboard_img from '../../images/banner/logo_ally_seaboardmarine.svg';
import logo_ally_trailerbridge_img from '../../images/banner/logo_ally_trailerbridge.svg';
import logo_crowley_img from '../../images/banner/logo_ally_crowley.svg';
import logo_interport_img from '../../images/banner/logo_ally_interport.svg';
import logo_cmacgm_img from '../../images/banner/logo_ally_cmacgm.svg';

import logo_visa_img from '../../images/banner/logo_payment_visa.svg';
import logo_mastercard_img from '../../images/banner/logo_payment_mastercard.svg';
import logo_americanexpress_img from '../../images/banner/logo_payment_americanexpress.svg';


const logos = {
    logo_nomanejes: { src: logo_nomanejes_img, alt: "Logo Nomanejes" },
    logo_puerto_rico_car_transfer: { src: logo_puertoricocartransfer_img, alt: "Logo Puerto Rico Car Transfer" },
    logo_carro_a_centro_america: { src: logo_carrosacentroamerica_img, alt: "Logo Carro a Centro America" },
    logo_pr_autotransport: { src: logo_prautotransport_img, alt: "Logo PR Autotransport" },
    logo_carmoversus: { src: logo_carmoversus_img, alt: "Logo Carmoversus" },
    logo_shipshippersusa: { src: logo_shipshippersusa_img, alt: "Logo Shipshippers USA" },
    logo_ratesautotransport: { src: logo_ratesautotransport_img, alt: "Logo Rates Autotransport" },
    logo_tucarroporusa: { src: logo_tucarroporusa_img, alt: "Logo Tu Carro Por Usa" },
    logo_tcpamerica: { src: logo_tcpamerica_img, alt: "Logo TCP America" },
}

const logos_ally = {
    logo_ally_centraldispatch: { src: logo_ally_centraldispatch_img, alt: "Logo Ally Central Dispatch" },
    logo_ally_seaboard: { src: logo_ally_seaboard_img, alt: "Logo Ally Seaboard" },
    logo_ally_trailerbridge: { src: logo_ally_trailerbridge_img, alt: "Logo Ally Trailer Bridge" },
    logo_crowley: { src: logo_crowley_img, alt: "Logo Crowley" },
    logo_interport: { src: logo_interport_img, alt: "Logo Interport" },
    logo_cmacgm: { src: logo_cmacgm_img, alt: "Logo CMACGM" },
}

const logos_payments = {
    logo_visa: { src: logo_visa_img, alt: "Logo Visa" },
    logo_mastercard: { src: logo_mastercard_img, alt: "Logo Mastercard" },
    logo_americanexpress: { src: logo_americanexpress_img, alt: "Logo Paypal" },
}


export default function BannerLogos() {
    return (
        <div className="d-flex flex-column w-100 gap-1 justify-content-between">
            <div className="d-flex justify-content-between w-100">
                <img src={logos.logo_nomanejes.src} width={"90px"} alt={logos.logo_nomanejes.alt} />
                <img src={logos.logo_puerto_rico_car_transfer.src} width={"140px"} alt={logos.logo_puerto_rico_car_transfer.alt} />
                <img src={logos.logo_carro_a_centro_america.src} width={"85px"} alt={logos.logo_carro_a_centro_america.alt} />
                <img src={logos.logo_pr_autotransport.src} width={"80px"} alt={logos.logo_pr_autotransport.alt} />
            </div>
            <div className="d-flex justify-content-between w-100">
                <img src={logos.logo_carmoversus.src} height={"40px"} alt={logos.logo_carmoversus.alt} />
                <img src={logos.logo_shipshippersusa.src} height={"40px"} alt={logos.logo_shipshippersusa.alt} />
            </div>

            <div className="d-flex justify-content-between w-100">
                <img src={logos.logo_ratesautotransport.src} height={"45px"} alt={logos.logo_ratesautotransport.alt} />
                <img src={logos.logo_tucarroporusa.src} height={"28px"} alt={logos.logo_tucarroporusa.alt} />
                <img src={logos.logo_tcpamerica.src} height={"40px"} alt={logos.logo_tcpamerica.alt} />
            </div>

            <div className="d-flex justify-content-between w-100 gap-3">
                <img src={logos_ally.logo_ally_centraldispatch.src} height={"38px"} alt={logos_ally.logo_ally_centraldispatch.alt} />
                <img src={logos_ally.logo_ally_seaboard.src} height={"30px"} alt={logos_ally.logo_ally_seaboard.alt} />
                <img src={logos_ally.logo_ally_trailerbridge.src} height={"30px"} alt={logos_ally.logo_ally_trailerbridge.alt} />
            </div>

            <div className="d-flex justify-content-between align-items-center w-100">
                <img src={logos_ally.logo_crowley.src} height={"40px"} alt={logos_ally.logo_crowley.alt} />
                <img src={logos_ally.logo_interport.src} height={"80px"} alt={logos_ally.logo_interport.alt} />
                <img src={logos_ally.logo_cmacgm.src} height={"20px"} alt={logos_ally.logo_cmacgm.alt} />
            </div>

            <div className="d-flex justify-content-center gap-3 w-100 align-items-center">
                <img src={logos_payments.logo_visa.src} height={"25px"} alt={logos_payments.logo_visa.alt} />
                <img src={logos_payments.logo_mastercard.src} height={"35px"} alt={logos_payments.logo_mastercard.alt} />
                <img src={logos_payments.logo_americanexpress.src} height={"25px"} alt={logos_payments.logo_americanexpress.alt} />
                <div className='d-flex w-100 justify-content-end '>
                    <p className="text-center m-0" style={{ fontSize: "1.1em" }} >Comunícate con alguna de nuestras empresas HOY.</p>
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import Slider2 from '../../images/slider2.png'
import MobileForm from '../Form/MobileForm'
import { Button } from 'react-bootstrap';
const AboutUS = () => {

    return (
        <div className='d-block d-md-none'>
            <img src={Slider2} alt="Puerto rico car transfer" className='img-fluid mb-5' />
            <div className='px-4 mb-5'>
                <p className='text-primary'>Puerto Rico Car Transfer</p>
                <h1 className='beba-font'>
                    ¿Quienes Somos?
                </h1>
                <p>
                Un proveedor líder de servicios de envío de automóviles  entre los puertos de San Juan, PR. y los puertos de Florida, Pennsylvania. Gestionamos el transporte terrestre dentro de los Estados Unidos hasta la puerta de tu casa y manejamos todo el papeleo.
                <br /><br />
                ¡La manera más rápida y confiable de enviar vehículos hacia  y desde Puerto Rico!
                </p>
                <Button className='w-100 p-3 mt-2 mb-5'>Cotiza Ahora</Button>

                <MobileForm></MobileForm>
            </div>
        </div>
    )
}

export default AboutUS
import React from 'react';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import Logo from '../images/logonav.png'
import Logo2 from '../images/logo.png'
import { useCustomNavigate } from '../utils/customNavigation';

const NavbarComponent = () => {
    const customNavigate = useCustomNavigate();

    return (
        <>
        <div className='d-none d-md-flex justify-content-center bg-dark py-2'>
                <span className='text-white'>¿Tienes preguntas? Llámanos </span> <a className='text-warning ms-1' href='tel:+17877416032'> (1) 787-741-6032</a>
        </div>
        <Navbar bg="white" expand="lg" className='d-none d-md-block' sticky='top'>
            <Container>
                <Navbar.Brand href="/">
                    <img src={Logo} alt="puertoricocartransfer" width={200} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto d-flex align-items-center">
                        <Nav.Link onClick={() => customNavigate('#us')} className="p-2 text-dark me-4">Nosotros</Nav.Link>
                        <Nav.Link onClick={() => customNavigate('#documentos')} className="p-2 text-dark me-4">Documentos</Nav.Link>
                        <Nav.Link href="/faq" className="p-2 text-dark me-4">FAQ</Nav.Link>
                        <Nav.Link onClick={() => customNavigate('#requirements')} className="p-2 text-dark me-3">Requirements</Nav.Link>
                        <Button variant="secondary" href='/' className="">Cotiza ahora</Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Navbar style={{backgroundColor: 'rgb(255,255,255, 0.6)'}} expand="lg" className='d-block d-md-none' sticky='top'>
            <Container>
                <Navbar.Brand href="/">
                    <img src={Logo2} alt="puertoricocartransfer" width={50} className='ms-3 p-1' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto d-flex align-items-start beba-font fs-6">
                        <Nav.Link href="/" className="p-2 text-dark mt-2">INICIO</Nav.Link>
                        <Nav.Link href="/about-us" className="p-2 text-dark mt-2">Nosotros</Nav.Link>
                        <Nav.Link href="/documentos" className="p-2 text-dark mt-2">Documentos</Nav.Link>
                        <Nav.Link href="/faq" className="p-2 text-dark mt-2">Preguntas frecuentes</Nav.Link>
                        <Nav.Link href="/contact-us" className="p-2 text-dark mt-2">Contacto</Nav.Link>
                        <Nav.Link onClick={() => customNavigate('#mobileForm')} className="p-2 text-dark mt-2">Cotiza</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
    );
};

export default NavbarComponent;

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
// Import your image
import CarTransfer from '../images/cartransfer.png';

const Hero2Component = ({ scrollToForm }) => {
    const [activeTab, setActiveTab] = useState('reserva');

    // Handler to change active tab
    const handleSelectTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
        <div className="text-center my-5 d-none d-md-block"  id="us">
            <p className='text-primary fs-6' style={{marginBottom: '-3px'}}>Llevamos tu vehículo</p>
            <h1 className='beba-font' style={{fontSize: '60px'}}>Fácil, barato, seguro</h1>
            {/* Uncomment and use your image */}
            <img src={CarTransfer} alt="Vehicle Transport" className='w-75 my-4' />
            
            <ul className="nav nav-tabs justify-content-center mt-2" role="tablist">
                <li className="nav-item" role="presentation" style={{borderRight: '1.5px solid #000000'}}>
                    <div className={`nav-link ${activeTab === 'reserva' ? 'active' : ''}`} onClick={() => handleSelectTab('reserva')}>Reserva tu cupo</div>
                </li>
                <li className="nav-item" role="presentation" style={{borderRight: '1.5px solid #000000'}}>
                    <div className={`nav-link ${activeTab === 'precios' ? 'active' : ''}`} onClick={() => handleSelectTab('precios')}>Precios Competitivos</div>
                </li>
                    <li className="nav-item" role="presentation" style={{borderRight: '1.5px solid #000000'}}>
                    <div className={`nav-link ${activeTab === 'seguro' ? 'active' : ''}`} onClick={() => handleSelectTab('seguro')}>Seguro Full Cover</div>
                </li>
                    <li className="nav-item" role="presentation">
                    <div className={`nav-link ${activeTab === 'soporte' ? 'active' : ''}`} onClick={() => handleSelectTab('soporte')}>Soporte 24/7</div>
                </li>
            </ul>

            {/* Displaying variable data based on active tab */}
            <div>
                {activeTab === 'reserva' && <div className=' d-flex justify-content-center my-3'><p className='text-center w-30 custom-width-sm '>Confía en nosotros para un Traslado Seguro de Vehículos a/y desde Puerto Rico.<br></br>¡Las mejores ofertas te esperan!</p></div> }
                {activeTab === 'precios' && <div className=' d-flex justify-content-center my-3'><p className='text-center w-30 custom-width-sm '>Recibe hasta 5 cotizaciones, con precios competitivos en todos los envíos. <br/>¡Sin cargos escondidos de ningun tipo!</p></div> }
                {activeTab === 'seguro' && <div className=' d-flex justify-content-center my-3'><p className='text-center w-30 custom-width-sm '>Tendrás cobertura del 100% del valor de tu vehículo.</p></div> }
                {activeTab === 'soporte' && <div className=' d-flex justify-content-center my-3'><p className='text-center w-30 custom-width-sm '>Nos importan nuestros clientes y les ofrecemos la mejor experiencia posible. Estamos disponibles 24/7 para resolver cualquier problema que surja.</p></div> }
            </div>

            <Button variant="primary" className='px-4' onClick={scrollToForm}>Reserva ya</Button>
        </div>
        <div className="text-center my-5 d-block d-md-none"  >
            <p className='text-primary ' style={{marginBottom: '-3px', fontSize: '1.3em'}}>Llevamos tu vehículo</p>
            <h1 className='beba-font' style={{fontSize: '2.8em'}}>Fácil, barato, seguro</h1>
            {/* Uncomment and use your image */}
            <img src={CarTransfer} alt="Vehicle Transport" className='w-75 my-4' />
            
            <ul className="nav nav-tabs justify-content-center mt-2" role="tablist" style={{fontSize: '1em'}}>
                <li className="nav-item px-2" role="presentation" style={{borderRight: '1.5px solid #000000'}}>
                    <div className={`nav-link ${activeTab === 'reserva' ? 'active' : ''}`} onClick={() => handleSelectTab('reserva')}>Reserva</div>
                </li>
                <li className="nav-item px-2" role="presentation" style={{borderRight: '1.5px solid #000000'}}>
                    <div className={`nav-link ${activeTab === 'precios' ? 'active' : ''}`} onClick={() => handleSelectTab('precios')}>Barato</div>
                </li>
                    <li className="nav-item px-2" role="presentation" style={{borderRight: '1.5px solid #000000'}}>
                    <div className={`nav-link ${activeTab === 'seguro' ? 'active' : ''}`} onClick={() => handleSelectTab('seguro')}>Garantías</div>
                </li>
                    <li className="nav-item px-2" role="presentation">
                    <div className={`nav-link ${activeTab === 'soporte' ? 'active' : ''}`} onClick={() => handleSelectTab('soporte')}>Soporte</div>
                </li>
            </ul>

            {/* Displaying variable data based on active tab */}
            <div>
                {activeTab === 'reserva' && <div className=' d-flex justify-content-center my-3'><p className='text-center w-75 custom-width-sm '>Confía en nosotros para un Traslado Seguro de Vehículos a/y desde Puerto Rico.<br></br>¡Las mejores ofertas te esperan!</p></div> }
                {activeTab === 'precios' && <div className=' d-flex justify-content-center my-3'><p className='text-center w-75 custom-width-sm '>Recibe hasta 5 cotizaciones, con precios competitivos en todos los envíos. <br/>¡Sin cargos escondidos de ningun tipo!</p></div> }
                {activeTab === 'seguro' && <div className=' d-flex justify-content-center my-3'><p className='text-center w-75 custom-width-sm '>Tendrás cobertura del 100% del valor de tu vehículo.</p></div> }
                {activeTab === 'soporte' && <div className=' d-flex justify-content-center my-3'><p className='text-center w-75 custom-width-sm '>Nos importan nuestros clientes y les ofrecemos la mejor experiencia posible. Estamos disponibles 24/7 para resolver cualquier problema que surja.</p></div> }
            </div>

            <Button variant="primary" className='w-75 p-3' onClick={scrollToForm}>Reserva ya</Button>
        </div>
        
        </>
    );
};

export default Hero2Component;


import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { useCustomNavigate } from '../utils/customNavigation';

const AccordionComponent = () => {
  const customNavigate = useCustomNavigate();

    const accordionItems = [
  
        {
            title: '¿Cuánto tiempo tarda la entrega de un vehículo?',
            content: 'El tiempo de entrega dependerá de la cantidad de millas recorridas y de algunas condiciones como el clima y el tráfico. Rutas entre 500 y 1,000 millas pueden tardar de 2 a 3 días. Rutas entre 1,000 y 2,000 millas pueden tardar de 4 a 7 días. Rutas de más de 2,000 millas pueden tardar entre 7 y 12 días.'
          },
          {
            title: '¿Qué está cubierto por el seguro?',
            content: 'SOLO cubrimos el seguro del vehículo en nuestra cotización. Sus pertenencias en el auto no forman parte de la cotización del seguro. Tenga en cuenta que el seguro de estas pertenencias en el vehículo no está en su contrato vinculante. Cualquier daño a las pertenencias dentro del automóvil no será responsabilidad de PuertoRicoCarTransfer.'
          },
          {
            title: '¿Debo estar presente todo el tiempo?',
            content: 'No, no tienes que estar presente durante toda la duración del proceso de recogida o entrega del envío. Sin embargo, recomendamos encarecidamente tener a alguien que sea mayor de edad disponible durante la carga o descarga del vehículo.'
          },
          {
            title: '¿Puede enviar su coche con cosas dentro?',
            content: '¡Sí! Los clientes no siempre tienen tiempo para sacar sus pertenencias de su vehículo antes del inicio del servicio, por lo que a menudo transportamos coches con pequeñas pertenencias dentro. Entendemos y queremos hacer nuestra parte para que nuestros clientes se sientan cómodos. Nuestros transportistas de confianza y profesionales transportarán su vehículo sin ninguna pérdida. Tenga en cuenta que el seguro de estas posesiones en el vehículo no está en su contrato vinculante. Cualquier daño a las pertenencias dentro del coche no será responsabilidad de PuertoRicoCarTransfer. Por favor, retire cualquier pertenencia colocada en el asiento del conductor.'
          },
          {
            title: '¿Cómo se calcula la tarifa de transporte de su vehículo?',
            content: 'Nos gusta mantener las cosas eficientes y directas. Pedimos a nuestros clientes que proporcionen detalles básicos sobre sus vehículos, como el tamaño, el peso, la distancia entre la recogida y la entrega, y la elección entre el transporte de vehículos abierto o cerrado. Al hacerlo, podemos evaluar con precisión las tarifas de transporte del vehículo en consecuencia. A diferencia de otras compañías de transporte, no solicitamos tarifas adicionales.'
          },
          {
            title: '¿Qué pasa si quiero pagar con tarjeta de crédito?',
            content: 'Aceptamos diversas formas de pago que van desde tarjetas de crédito hasta PayPal. Los clientes pueden pagar en efectivo contra entrega (COD), pero se requiere un pago de depósito para asegurar su servicio de transporte.'
          },
          {
            title: '¿Qué pasa si mi coche es pesado? ¿Eso resulta en un presupuesto de entrega más alto?',
            content: 'Calculamos nuestras tarifas de acuerdo con el tamaño y el peso del vehículo sin cargos adicionales al cliente.'
          },
          {
            title: '¿Hay un pago inicial?',
            content: 'No, no hay tarifas ocultas ni cargos por consultas. Sin embargo, si opta por pagar en efectivo contra entrega (COD), se le requerirá hacer un depósito hacia el servicio de transporte seleccionado para asegurar su reserva.'
          }
];


    return (
        <>
        {/* DESKTOP VIEW */}
        <section style={{backgroundColor: '#ECF8FF'}} id='requirements' className='d-none d-md-block'>
            <div className='d-flex justify-content-center align-items-center'> 
                <h1 className='beba-font text-primary mt-5' style={{fontSize: '4em'}}>Preguntas frecuentes</h1>
            </div>
            <div className='w-75 mx-auto bg-transparent p-5'>
            <Accordion defaultActiveKey="3" className='bg-transparent'>
                {accordionItems.map((item, index) => (
                    <Accordion.Item eventKey={`${index}`} key={index}>
                    <Accordion.Header className='beba-font bg-transparent'> {item.title}</Accordion.Header>
                    <Accordion.Body className='bg-transparent mx-5'>
                        {item.content}
                    </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            </div>
        </section>
        {/* MOBILE VIEW */}
        <section style={{backgroundColor: '#ECF8FF'}} id='requirements' className='d-block d-md-none'>
                <div className='bg-dark py-5 text-white'> 
                    <p className='pt-4 beba-font h1 mb-4 text-center'>¿Tienes dudas?</p>
                    <h1 className='beba-font text-center ' style={{fontSize: '6em', lineHeight: '0.8'}}>Preguntas frecuentes</h1>
                    <p className='text-center mx-auto mt-4 w-75'>Si necesitas ayuda, no dudes en comunicarte con nosotros y gestionaremos el proceso</p>
                    <div
                        className="d-flex flex-column mt-3 mx-auto w-75"
                        style={{ position: "relative", zIndex: 1 }}
                    >
                        <Button className="my-4 p-2" onClick={() => customNavigate('#mobileForm')}>Contactanos</Button>
                        <a className="btn btn-outline-success" href='tel:+17877416032'>(1) 787-741-6032 </a>
                    </div>
                </div>
                <div className='w-100 mx-auto bg-transparent p-5'>
                <Accordion defaultActiveKey="3" className='bg-transparent'>
                    {accordionItems.map((item, index) => (
                        <Accordion.Item eventKey={`${index}`} key={index}>
                        <Accordion.Header className='beba-font bg-transparent'> {item.title}</Accordion.Header>
                        <Accordion.Body className='bg-transparent mx-2'>
                            {item.content}
                        </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                </div>
        </section>
        </>
    )
  };
  
  export default AccordionComponent;
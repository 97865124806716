import "../../LogoSlider.css";
import { useEffect } from 'react';
import logo_nomanejes_img from '../../images/banner/logo_nomanejes.svg';
import logo_puertoricocartransfer_img from '../../images/banner/logo_puertoricocartransfer.svg';
import logo_carrosacentroamerica_img from '../../images/banner/logo_carrosacentroamerica.svg';
import logo_prautotransport_img from '../../images/banner/logo_prautotransport.svg';
import logo_carmoversus_img from '../../images/banner/logo_carmoversus.svg';
import logo_shipshippersusa_img from '../../images/banner/logo_shipshippersusa.svg';
import logo_ratesautotransport_img from '../../images/banner/logo_ratesautotransport.svg';
import logo_tucarroporusa_img from '../../images/banner/logo_tucarroporusa.svg';
import logo_tcpamerica_img from '../../images/banner/logo_tcpamerica.svg';

import logo_ally_centraldispatch_img from '../../images/banner/logo_ally_centraldispatch.svg';
import logo_ally_seaboard_img from '../../images/banner/logo_ally_seaboardmarine.svg';
import logo_ally_trailerbridge_img from '../../images/banner/logo_ally_trailerbridge.svg';
import logo_crowley_img from '../../images/banner/logo_ally_crowley.svg';
import logo_interport_img from '../../images/banner/logo_ally_interport.svg';
import logo_cmacgm_img from '../../images/banner/logo_ally_cmacgm.svg';

import logo_visa_img from '../../images/banner/logo_payment_visa.svg';
import logo_mastercard_img from '../../images/banner/logo_payment_mastercard.svg';
import logo_americanexpress_img from '../../images/banner/logo_payment_americanexpress.svg';


const logos = {
    logo_nomanejes: { src: logo_nomanejes_img, alt: "Logo Nomanejes" },
    logo_puerto_rico_car_transfer: { src: logo_puertoricocartransfer_img, alt: "Logo Puerto Rico Car Transfer" },
    logo_carro_a_centro_america: { src: logo_carrosacentroamerica_img, alt: "Logo Carro a Centro America" },
    logo_pr_autotransport: { src: logo_prautotransport_img, alt: "Logo PR Autotransport" },
    logo_carmoversus: { src: logo_carmoversus_img, alt: "Logo Carmoversus" },
    logo_shipshippersusa: { src: logo_shipshippersusa_img, alt: "Logo Shipshippers USA" },
    logo_ratesautotransport: { src: logo_ratesautotransport_img, alt: "Logo Rates Autotransport" },
    logo_tucarroporusa: { src: logo_tucarroporusa_img, alt: "Logo Tu Carro Por Usa" },
    logo_tcpamerica: { src: logo_tcpamerica_img, alt: "Logo TCP America" },
}

const logos_ally = {
    logo_ally_centraldispatch: { src: logo_ally_centraldispatch_img, alt: "Logo Ally Central Dispatch" },
    logo_ally_seaboard: { src: logo_ally_seaboard_img, alt: "Logo Ally Seaboard" },
    logo_ally_trailerbridge: { src: logo_ally_trailerbridge_img, alt: "Logo Ally Trailer Bridge" },
    logo_crowley: { src: logo_crowley_img, alt: "Logo Crowley" },
    logo_interport: { src: logo_interport_img, alt: "Logo Interport" },
    logo_cmacgm: { src: logo_cmacgm_img, alt: "Logo CMACGM" },
}

const logos_payments = {
    logo_visa: { src: logo_visa_img, alt: "Logo Visa" },
    logo_mastercard: { src: logo_mastercard_img, alt: "Logo Mastercard" },
    logo_americanexpress: { src: logo_americanexpress_img, alt: "Logo Paypal" },
}


export default function BannerLogosMobile() {
    useEffect(() => {
        const logoContainer = document.getElementById("logo-container");
        const logoAllyContainer = document.getElementById("logo-ally-container");
        if (logoContainer) {
            logoContainer.innerHTML += logoContainer.innerHTML;
        }
        if (logoAllyContainer) {
            logoAllyContainer.innerHTML += logoAllyContainer.innerHTML;
        }
    }, []);

    return (
        <div className="d-flex flex-column gap-5 w-100 container py-5">
            <div className="logo-slider overflow-hidden position-relative mb-5">
                <div className="logo-track d-inline-block" id="logo-container">
                    {
                        Object.values(logos).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-img" />
                        ))
                    }

                    {
                        Object.values(logos).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-img" />
                        ))
                    }
                </div>
            </div>
            <div className="logo-slider overflow-hidden position-relative mb-5">
                <div className="logo-track d-inline-block" id="logo-ally-container">
                    {
                        Object.values(logos_ally).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-img" />
                        ))
                    }
                    {
                        Object.values(logos_ally).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-img" />
                        ))
                    }
                </div>
            </div>
            <div className="d-flex gap-2 justify-lg-content-between justify-content-center">
                <img src={logos_payments.logo_visa.src} alt="Visa" className="payment-logo" />
                <img src={logos_payments.logo_mastercard.src} alt="Mastercard" className="payment-logo" />
                <img src={logos_payments.logo_americanexpress.src} alt="American Express" className="payment-logo" />
            </div>
            <div className="text-center">
                <p className="fs-5">Comunícate con alguna de nuestras empresas HOY.</p>
            </div>
        </div>
    );
}
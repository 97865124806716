import React from 'react';
import { Container } from 'react-bootstrap';

const HeroSection = () => {
    return (
        <div className="bg-light d-flex justify-content-center align-items-center p-5 w-100 d-none d-md-flex mt-5" style={{height: '200px'}}>
            <Container className="text-center beba-font h2">
                <span className="m-0">¡ASESORATE GRATIS Y LLAMA YA! </span> 
                <a href='tel:+17877416032' className="text-primary m-0" style={{ textDecoration: 'none' }}>(1) 787-741-6032</a>
            </Container>
        </div>
    );
};

export default HeroSection;
